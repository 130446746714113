import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {bindActionCreators} from "redux";
import * as commonActions from "../../../../common/actions";
import swal from 'sweetalert2';
import StaticLoader from '../../../../common/loader/StaticLoader';
import Api from '../../../../../lib/api';
import { INSERT_NEW_RECORD, UPDATE_EXISTING_RECORD } from '../../../../../lib/appConstants';

class CoveragePolicyValidations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const urlHash = match.params.urlhash;
    const payload = {
      URLHash: urlHash
    };

    this.submitCoveragePolicyValidations(payload);
  }


  submitCoveragePolicyValidations = (payload) => {
    const policyValidationEndpoint = `cf/coverage/policyValidations`;
    this.setState({ isLoading: false });
    Api.post(policyValidationEndpoint, payload)
      .then((response) => {
        if (response.data.success && [INSERT_NEW_RECORD, UPDATE_EXISTING_RECORD].includes(response.data.ResponseCode)) {
          swal({
            showConfirmButton: true,
            allowOutsideClick:false,
            type: 'success',
            titleText: 'Thank you for your response.',
            text: "You are good to close the browser window.",
            confirmButtonText: 'Close',
            confirmButtonColor: '#28A745',
          }).then((result) => {
            window.close();
          });

        } else {
          swal({
            showConfirmButton: true,
            allowOutsideClick:false,
            type: 'error',
            titleText: 'The link is not valid anymore.',
            text: "Please close the window.",
            confirmButtonText: 'Close',
            confirmButtonColor: '#2E5965',
          }).then((result) => {
            window.close();
          });
        }
      })
      .catch((error) => {
        swal({
          showConfirmButton: true,
          allowOutsideClick:false,
          type: 'error',
          titleText: 'The link is not valid anymore.',
          text: "Please close the window.",
          confirmButtonText: 'Close',
          confirmButtonColor: '#2E5965',
        }).then((result) => {
          window.close();
        });
      });
    }

  render() {
    const { isLoading } = this.state;

    return (
      <>
        {isLoading && <StaticLoader />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.login.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoveragePolicyValidations));
